import { ReactComponent as LogoLiga } from '../../assets/images/logo-liga.svg';
import { ReactComponent as MenuToggle } from '../../assets/images/menu-toggle.svg';

import styles from './Header.module.scss';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import HiddenScroll from '../App/HideScroll';


const menuItems = [
  {
    label: 'О мероприятии',
    link: '#about',
  },
  {
    label: 'Артисты',
    link: '#artists',
  },
  {
    label: 'Купить билет',
    link: '#formats',
  },
];

function NavList({ navClass, onClick }) {
  return (
    <nav className={`${styles.nav} ${navClass}`}>
      <ul className={styles.list}>
        {menuItems.map((item) => (
          <li onClick={onClick} key={item.label}>
            <a className={styles.link} href={item.link}>{item.label}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

function Header() {
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' });
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const handleClickLink = () => {
    setIsOpenMenu(false)
  }

  return (
    <header className={styles.header}>
      <LogoLiga className={styles.logo} />

      <NavList navClass={styles.navDesktop} />

      <a className={`${styles.link} ${styles.linkUser} ${styles.linkUserDesktop}`} href="https://heroleague.ru/myevents">Личный кабинет</a>

      {isTablet && (
        // <button className={styles.toggle} type="button">
        //   <MenuToggle />
        // </button>

        <div className={styles.navContainer}>
          <input
            className={`${styles.checkbox} visually-hidden`}
            onChange={() => setIsOpenMenu((prev) => !prev)}
            type="checkbox"
            id="menu-checkbox"
            checked={isOpenMenu}
          />
          <label className={styles.hamburgerLines} htmlFor="menu-checkbox">
            <span className={`${styles.line} ${styles.line1}`} />
            <span className={`${styles.line} ${styles.line2}`} />
            <span className={`${styles.line} ${styles.line3}`} />
          </label>
        </div>
      )}

      {(isTablet && isOpenMenu) && (
        <div className={styles.navModal}>
          <NavList navClass={styles.navMobile} onClick={handleClickLink} />

          <a className={`${styles.link} ${styles.linkUser} ${styles.linkUserMobile}`} href="https://heroleague.ru/myevents">Личный кабинет</a>

          <HiddenScroll />
        </div>
      )}
    </header>
  );
}

export default Header;
