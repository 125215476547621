import styles from './Partners.module.scss';
import { partners } from './partners-data';
import Container from '../Container/Container';


const Partners = () => {
  return (
    <section className={styles.container}>
      <Container>
        {partners.map((partner) => (
          <section className={styles.partnerList} key={partner.id}>
            <h2 className={styles.label}>{partner.title}</h2>

            <ul className={styles.partnerLogos}>
              {partner.items.map((item) => (
                <li className={styles.partnerLogosItem} key={item.id}>
                  {item.link ? (
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      <img className={styles.logoImg} src={item.logo} width={item.width} alt={`Партнер`}/>
                    </a>
                  ) : (
                    <img className={styles.logoImg} src={item.logo} width={item.width} alt={`Партнер`}/>
                  )}
                </li>
              ))}
            </ul>
          </section>
        ))}
      </Container>
    </section>
  )
}

export default Partners;
