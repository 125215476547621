import styles from './About.module.scss';
import Container from '../Container/Container';


function About() {
  return (
    <section className={styles.about} id="about">
      <Container>
        <div className={styles.aboutContainer}>
          <h2 className={styles.title}>Гонке Героев 10&nbsp;лет!</h2>

          <div>
            <p className={styles.text}>
            Лига Героев организовывает яркий музыкальный фестиваль для всех любителей активного отдыха. Приглашаем вместе с нами отметить День рождения легендарного спортивного проекта и насладиться любимой музыкой в живом исполнении!
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default About;
