import styles from './Footer.module.scss';
import mainLogo from '../../assets/images/logo-liga-white.svg';
import logoMl from '../../assets/images/logo-ml-white.svg';
import Socials from './Socials/Socials';
import Container from '../Container/Container';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerWrapper}>
          <div className={styles.development}>
            <img src={logoMl} width="127" height="26" alt="Логотип Marketing League"/>
            <p className={styles.developmentText}>Концепция и воплощение:</p>
          </div>
          <div className={styles.logo}>
            <img className={styles.mainLogo} src={mainLogo} width="72" height="46" alt="Логотип Лиги Героев"/>
          </div>
          <div className={styles.socials}>
            <Socials />
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer;
