import styles from './App.module.scss';
import Header from './components/Header/Header';
import TopPage from './components/TopPage/TopPage';
import About from './components/About/About';
import Activities from './components/Activities/Activities';
import Artists from './components/Artists/Artists';
import Schedule from './components/Schedule/Schedule';
import Preferences from './components/Preferences/Preferences';
import Partners from './components/Partners/Partners';
import Footer from './components/Footer/Footer';
import Formats from './components/Formats/Formats';
import Presenters from './components/Presenters/Presenters';
import Gallery from './components/Gallery/Gallery';


function App() {
  return (
    <div className={styles.app}>
      <Header />
      <TopPage />
      <About />
      <Artists />
      <Activities />
      <Presenters />
      <Schedule />
      <Formats />
      <Gallery />
      <Preferences />
      <Partners />
      <Footer />
    </div>
  );
}

export default App;
