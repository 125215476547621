import styles from './Artists.module.scss';
import Container from '../Container/Container';
import Persons from '../Persons/Persons';
import persons11x from '../../assets/images/persons/persons1@2x.jpg';
import persons21x from '../../assets/images/persons/persons2@2x.jpg';
import persons31x from '../../assets/images/persons/persons3@2x.jpg';
import persons41x from '../../assets/images/persons/persons4@2x.jpg';
import persons51x from '../../assets/images/persons/persons5@2x.jpg';
import persons61x from '../../assets/images/persons/persons6@2x.jpg';
import persons71x from '../../assets/images/persons/persons7@2x.jpg';
import persons81x from '../../assets/images/persons/persons8@2x.jpg';
import persons91x from '../../assets/images/persons/persons9@2x.jpg';
import persons101x from '../../assets/images/persons/persons10@2x.jpg';
import persons111x from '../../assets/images/persons/persons11@2x.jpg';
import persons121x from '../../assets/images/persons/persons12@2x.jpg';
import persons131x from '../../assets/images/persons/persons13@2x.jpg';
import persons141x from '../../assets/images/persons/persons14@2x.jpg';
import persons151x from '../../assets/images/persons/persons15@2x.jpg';
import persons11xWebp from '../../assets/images/webp/persons/persons1@2x.webp';
import persons21xWebp from '../../assets/images/webp/persons/persons2@2x.webp';
import persons31xWebp from '../../assets/images/webp/persons/persons3@2x.webp';
import persons41xWebp from '../../assets/images/webp/persons/persons4@2x.webp';
import persons51xWebp from '../../assets/images/webp/persons/persons5@2x.webp';
import persons61xWebp from '../../assets/images/webp/persons/persons6@2x.webp';
import persons71xWebp from '../../assets/images/webp/persons/persons7@2x.webp';
import persons81xWebp from '../../assets/images/webp/persons/persons8@2x.webp';
import persons91xWebp from '../../assets/images/webp/persons/persons9@2x.webp';
import persons101xWebp from '../../assets/images/webp/persons/persons10@2x.webp';
import persons111xWebp from '../../assets/images/webp/persons/persons11@2x.webp';
import persons121xWebp from '../../assets/images/webp/persons/persons12@2x.webp';
import persons131xWebp from '../../assets/images/webp/persons/persons13@2x.webp';
import persons141xWebp from '../../assets/images/webp/persons/persons14@2x.webp';
import persons151xWebp from '../../assets/images/webp/persons/persons15@2x.webp';
import Title from '../Title/Title';
import ResponsiveImage from '../Ui/ResponsiveImage/ResponsiveImage';


const artists = [
  {
    id: 1,
    img: persons111x,
    imgWebp: persons111xWebp,
    name: 'Леонид Агутин',
  },
  {
    id: 2,
    img: persons61x,
    imgWebp: persons61xWebp,
    name: 'IOWA',
  },
  {
    id: 3,
    img: persons11x,
    imgWebp: persons11xWebp,
    name: 'Galibri&Mavik',
  },
];

const djs = [
  {
    id: 1,
    img: persons81x,
    imgWebp: persons81xWebp,
    name: 'ALEX FRESH',
  },
  {
    id: 2,
    img: persons121x,
    imgWebp: persons121xWebp,
    name: 'Lika Svetlova',
  },
  {
    id: 3,
    img: persons91x,
    imgWebp: persons91xWebp,
    name: 'VASSCA',
  },
];

function Artists() {
  return (
    <Container>
      <section className={styles.artists} id="artists">
        <Title className={styles.title}>Артисты</Title>

        <ul className={`${styles.list} ${styles.list1}`}>
          {artists.map((item) => (
            <li className={styles.item}>
              <div>
                <ResponsiveImage
                  classNameImage={styles.img}
                  src={item.img}
                  srcWebp={item.imgWebp}
                  alt={item.name}
                />
              </div>
              <p className={styles.name}>{item.name}</p>
            </li>
          ))}
        </ul>

        <Title className={styles.title}>DJ</Title>

        <ul className={styles.list}>
          {djs.map((item) => (
            <li className={styles.item}>
              <div>
                <ResponsiveImage
                  classNameImage={styles.img}
                  src={item.img}
                  srcWebp={item.imgWebp}
                  alt={item.name}
                />
              </div>
              <p className={styles.name}>{item.name}</p>
            </li>
          ))}
        </ul>
      </section>
    </Container>
  );
}

export default Artists;
