import styles from './TopPage.module.scss';
import { ReactComponent as Hno } from '../../assets/images/HNO.svg';
import { ReactComponent as Partner1 } from '../../assets/images/partners/partner4.svg';
import { ReactComponent as Partner2 } from '../../assets/images/partners/partner1.svg';
import { useMediaQuery } from 'react-responsive';


function TopPage() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <section className={styles.topPage}>
      <div className={styles.content}>
        <div className={styles.datePlace}>
          <p>30/09</p>
          <p>live арена</p>
        </div>
      </div>

      <div className={styles.logoWrap}>
        <p className={styles.text1}>гонке героев 10 лет</p>
        <Hno className={styles.logo} />
        <p className={styles.text2}>Музыкальный фестиваль</p>
      </div>

      <div className={styles.bottom}>
        <div className={styles.partner1}>
          <span>Официальный партнёр</span>
          <Partner1 className={styles.partnerLogo1} />
        </div>

        <a className={styles.btn} href="#formats">
          <svg className={styles.btnBg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 325 60" preserveAspectRatio="none" fill="none">
            <mask id="path-1-inside-1_44_84" fill="white">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 13.5001V60L305 59.9999L325 39.9999V0H13.5001L0 13.5001Z"/>
            </mask>
            <path fillRule="evenodd" clipRule="evenodd" d="M0 13.5001V60L305 59.9999L325 39.9999V0H13.5001L0 13.5001Z" fill="#BD1818"/>
            <path d="M0 13.5001L-2.12132 11.3787L-3 12.2574V13.5001H0ZM0 60H-3V63H1.20069e-06L0 60ZM305 59.9999V62.9999H306.243L307.121 62.1212L305 59.9999ZM325 39.9999L327.121 42.1212L328 41.2425V39.9999H325ZM325 0H328V-3H325V0ZM13.5001 0V-3H12.2574L11.3787 -2.12132L13.5001 0ZM-3 13.5001V60H3V13.5001H-3ZM1.20069e-06 63L305 62.9999V56.9999L-1.20069e-06 57L1.20069e-06 63ZM307.121 62.1212L327.121 42.1212L322.879 37.8786L302.879 57.8786L307.121 62.1212ZM328 39.9999V0H322V39.9999H328ZM325 -3H13.5001V3H325V-3ZM11.3787 -2.12132L-2.12132 11.3787L2.12132 15.6214L15.6214 2.12132L11.3787 -2.12132Z" fill="url(#paint0_linear_44_84)" mask="url(#path-1-inside-1_44_84)"/>
            <defs>
              <linearGradient id="paint0_linear_44_84" x1="-19" y1="-19" x2="316" y2="74" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFBB49"/>
                <stop offset="0.239583" stopColor="#F5EA77"/>
                <stop offset="0.4375" stopColor="#EFEA7A"/>
                <stop offset="0.645833" stopColor="#EAAE3F"/>
                <stop offset="0.8125" stopColor="#E9A638"/>
                <stop offset="1" stopColor="#DE971A"/>
              </linearGradient>
            </defs>
          </svg>

          <span className={styles.btnText}>Купить билет</span>
        </a>

        <div className={styles.partner2}>
          <Partner2 className={styles.partnerLogo2} />
          <span>Генеральный партнёр</span>
        </div>
      </div>
    </section>
  );
}

export default TopPage;
