function ResponsiveImage({ src, srcWebp, classNameImage, alt }) {
  return (
    <picture>
      <source
        srcSet={srcWebp}
        type="image/webp" />

      <img className={classNameImage} src={src} alt={alt} />
    </picture>
  );
}

export default ResponsiveImage;
