import styles from './Presenters.module.scss';
import Persons from '../Persons/Persons';
import persons141x from '../../assets/images/persons/persons14@2x.jpg';
import persons141xWebp from '../../assets/images/webp/persons/persons14@2x.webp';
import persons151x from '../../assets/images/persons/persons15@2x.jpg';
import persons151xWebp from '../../assets/images/webp/persons/persons15@2x.webp';
import persons21x from '../../assets/images/persons/persons2@2x.jpg';
import persons21xWebp from '../../assets/images/webp/persons/persons2@2x.webp';
import persons31x from '../../assets/images/persons/persons3@2x.jpg';
import persons31xWebp from '../../assets/images/webp/persons/persons3@2x.webp';
import persons41x from '../../assets/images/persons/persons4@2x.jpg';
import persons41xWebp from '../../assets/images/webp/persons/persons4@2x.webp';
import persons101x from '../../assets/images/persons/persons10@2x.jpg';
import persons101xWebp from '../../assets/images/webp/persons/persons10@2x.webp';
import persons131x from '../../assets/images/persons/persons13@2x.jpg';
import persons131xWebp from '../../assets/images/webp/persons/persons13@2x.webp';
import persons161x from '../../assets/images/persons/persons16.jpg';
import persons161xWebp from '../../assets/images/webp/persons/persons16.webp';
import Title from '../Title/Title';
import Container from '../Container/Container';


const presenters = [
  {
    id: 1,
    img: persons141x,
    imgWebp: persons141xWebp,
    name: 'Алексей Столяров',
  },
  {
    id: 2,
    img: persons151x,
    imgWebp: persons151xWebp,
    name: 'Габар',
  },
  {
    id: 3,
    img: persons101x,
    imgWebp: persons101xWebp,
    name: 'Александр Кузьмин',
  },
  {
    id: 4,
    img: persons21x,
    imgWebp: persons21xWebp,
    name: 'Вероника Гилева',
  },
  {
    id: 5,
    img: persons31x,
    imgWebp: persons31xWebp,
    name: 'Дмитрий Решетников',
  },
  {
    id: 6,
    img: persons41x,
    imgWebp: persons41xWebp,
    name: 'Сергей Мохов',
  },
  {
    id: 7,
    img: persons131x,
    imgWebp: persons131xWebp,
    name: 'Аксенов Григорий',
  },
  {
    id: 8,
    img: persons161x,
    imgWebp: persons161xWebp,
    name: 'Артур Насибулов',
  },
];

function Presenters() {
  return (
    <div className={styles.presentersWrapper}>
      <Container>
        <section className={styles.presenters}>
          <Persons data={presenters} label="Ведущие" variant="accordion" isOpenDefault />

          <Title className={styles.title}>Наведём шума и&nbsp;покажем, как&nbsp;надо веселиться!</Title>
        </section>
      </Container>
    </div>
  );
}

export default Presenters;
