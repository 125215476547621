import styles from './Title.module.scss';


function Title({ children, className }) {
  return (
    <h2 className={`${styles._title} ${className}`}>{children}</h2>
  );
}

export default Title;
