import styles from './Preferences.module.scss';
import Container from '../Container/Container';
import Title from '../Title/Title';


const preferences = [
  {
    id: 1,
    label: 'Презентация проектов',
    text: 'Эксклюзивное открытие календаря сезона 2024! Ты первым узнаешь о спортивных новинках Лиги Героев.',
  },
  {
    id: 2,
    label: 'Розыгрыши',
    text: 'Целый вечер разыгрываем крутые подарки: уникальный мерч, слоты на наши мероприятия и другое. Уверены, что повезет именно тебе!',
  },
  {
    id: 3,
    label: 'Мерч',
    text: 'Давно мечтал пополнить коллекцию стильным мерчем? На вечеринке тебя ждут специальные условия приобретения мерча и сувенирной продукции Лиги Героев.',
  },
  {
    id: 4,
    label: 'Гравировка',
    text: 'Сможешь сделать гравировку на любимой награде от Лиги Героев прямо во время мероприятия и абсолютно бесплатно! Мы умеем делать сюрпризы.',
  },
  {
    id: 5,
    label: 'Автограф-сессия',
    text: 'Тебя ждет незабываемый вечер со знаменитыми спортсменами и блогерами. Это твой шанс познакомиться с легендами и вдохновиться на новый результат!',
  },
  {
    id: 6,
    label: 'Печать фотографий',
    text: 'Печатаем самые жаркие и незабываемые кадры с любого спортивного события Лиги Героев за сезон 2023 бесплатно.',
  },
];

function Preferences() {
  return (
    <section className={styles.preferences}>
      <Container>
        <Title className={styles.title}>Привилегии от Лиги Героев</Title>

        <ul className={styles.list}>
          {preferences.map((item) => (
            <li className={styles.item} key={item.id}>
              <p className={styles.label}>{item.label}</p>
              <p className={styles.text}>{item.text}</p>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}

export default Preferences;
