import styles from './Activities.module.scss';
import Container from '../Container/Container';
import Title from '../Title/Title';


function Activities() {
  return (
    <section className={styles.activities}>
      <Container>
        <div className={styles.container}>
          <Title className={styles.title}>Что тебя ждёт?</Title>

          <ul className={styles.list}>
            <li className={styles.item}>
              <p className={styles.itemTitle}>Шоу-программа и музыкальный концерт</p>
              <p className={styles.itemText}>Пой, танцуй и наслаждайся классной музыкой!</p>
            </li>
            <li className={styles.item}>
              <p className={styles.itemTitle}>Автограф-сессия с блогерами и спортсменами</p>
              <p className={styles.itemText}>Познакомься с легендами и вдохновись на новый результат!</p>
            </li>
            <li className={styles.item}>
              <p className={styles.itemTitle}>Встреча с огромным комьюнити всех проектов Лиги Героев</p>
              <p className={styles.itemText}>Встретишь друзей по трассе и круто проведешь время с теми, кто разделяет твои интересы!</p>
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
}

export default Activities;
