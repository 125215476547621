import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { YMInitializer } from 'react-yandex-metrika';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback="Loading...">
      <YMInitializer accounts={[94768043]} options={{ webvisor: true }} />
      <App />
    </Suspense>
  </React.StrictMode>
);

reportWebVitals();
