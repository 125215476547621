import styles from './Schedule.module.scss';
import Container from '../Container/Container';


function Schedule() {
  return (
    <section className={styles.schedule}>
      <Container>
        <div className={styles.container}>
          <div className={styles.top}>
            <h2 className={styles.title}>Встречаемся 30 сентября</h2>

            <p className={styles.label}>Дресс-код:</p>

            <ul className={`${styles.list} ${styles.listCode}`}>
              <li>Black-Tie</li>
              <li>After Five</li>
              <li>Cocktail</li>
            </ul>
          </div>

          <div>
            <p className={styles.label}>Локация:</p>

            <ul className={`${styles.list} ${styles.listLocation}`}>
              <li>Live Арена</li>
              <li>МЦД Сколково</li>
              <li>Западная ул., д. 145</li>
            </ul>

            <p className={styles.label}>Программа:</p>

            <ul className={styles.list}>
              <li>17:30 — Сбор гостей</li>
              <li>18:00 — Программа привилегий для героев</li>
              <li>19:00 — Концертная шоу-программа</li>
              <li>23:00 — DJ-сет</li>
              <li>01:00 — Завершение мероприятия</li>
            </ul>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Schedule;
