export const getNextPrice = (obj) => {
  const { prices, limits, tickets_left, price } = obj;
  const soldOut = limits?.athlete - tickets_left?.athlete; // кол-во проданных билетов
  const currentIndex = prices.findIndex(el => el.price === price);
  const nextPrice = prices[currentIndex + 1];
  // const currentPrice = prices[currentIndex];

  if (nextPrice && tickets_left > 0) {
    const leftTicket = nextPrice.tickets_count - soldOut;
    return { tickets_left: { athlete: leftTicket }, nextPrice: nextPrice.price};
  } else {
    // остаток по текущей цене = остатку на формат
    return { tickets_left: { athlete: tickets_left.athlete }, nextPrice: null };
  }
};

export const getDate = (data) => {
  const months = [`Января`, `Февраля`, `Марта`, `Апреля`, `Мая`, `Июня`, `Июля`, `Августа`, `Сентября`, `Октября`, `Ноября`, `Декабря`,];
  const days = [`Воскресенье`, `Понедельник`, `Вторник`, `Среда`, `Четверг`, `Пятница`, `Суббота`,];

  let time = {};
  let unixTime = new Date(data * 1000);

  time.year = unixTime.getFullYear();
  time.month = months[unixTime.getMonth()];
  time.numMonth = unixTime.getMonth();
  time.date = unixTime.getDate();
  time.day = days[unixTime.getDay()];
  time.hour = unixTime.getHours();
  time.minute = unixTime.getMinutes();

  return time;
}


export const times = {
  getTime(date = 1) {
    let obj = getDate(date);
    let str = obj.hour + ":" + obj.minute;
    return str;
  },

  getDay(date = 1) {
    let obj = getDate(date);
    let str = obj.day;
    return str;
  },

  getDayOfWeek(date = 1) {
    let obj = getDate(date);
    let str = `${obj.date} ${obj.month}`;
    return str;
  },

  getFullDate(date = 1) {
    let obj = getDate(date);
    let str = `${obj.date} ${obj.month} ${obj.year}`;
    return str;
  },

  getShortDate(date = 1) {
    let obj = getDate(date);
    let str = `${obj.date} ${obj.month}`;
    return str;
  },

  getYear(date = 1) {
    let obj = getDate(date);
    let str = `${obj.year}`;
    return str;
  },

  getNumberDate(date = 1) {
    let obj = getDate(date);
    let day = obj.date < 10 ? `0${obj.date}` : obj.date;
    let month = obj.numMonth < 9 ? `0${obj.numMonth + 1}`: obj.numMonth + 1;
    // let year = obj.year.toString().slice(2, 4);
    let year = obj.year.toString();

    return `${year}-${month}-${day}`;
  },

  getNumberShortDate(date = 1) {
    let obj = getDate(date);
    let day = obj.date < 10 ? `0${obj.date}` : obj.date;
    let month = obj.numMonth < 9 ? `0${obj.numMonth + 1}`: obj.numMonth + 1;

    return `${day}.${month}`;
  },

  getNumberFullDate(date = 1) {
    let obj = getDate(date);
    let day = obj.date < 10 ? `0${obj.date}` : obj.date;
    let month = obj.numMonth < 9 ? `0${obj.numMonth + 1}`: obj.numMonth + 1;
    let year = obj.year.toString();

    return `${day}.${month}.${year}`;
  },
};

export const getCurrentPrice = (prices) => {
  const currentTime = Date.now() / 1000;
  return prices.find(p => (currentTime < p.start_date));
};
