import partner1 from '../../assets/images/partners/partner1.svg';
import partner2 from '../../assets/images/partners/partner2.svg';
import partner3 from '../../assets/images/partners/partner3.svg';
import partner4 from '../../assets/images/partners/partner4.svg';
import partner5 from '../../assets/images/partners/partner5.svg';

export const partners = [
  {
    id: 1,
    title: 'Генеральные Партнеры',
    items: [
      {
        id: 1,
        logo: partner1,
        width: 200,
      },
    ],
  },
  {
    id: 1,
    title: 'Официальный Партнер',
    items: [
      {
        id: 1,
        logo: partner4,
        width: 86,
      },
    ],
  },
  {
    id: 2,
    title: 'Партнеры',
    items: [
      {
        id: 1,
        logo: partner3,
        width: 200,
      },
      {
        id: 2,
        logo: partner5,
        width: 172,
      },
      {
        id: 3,
        logo: partner2,
        width: 200,
      },
    ],
  },
  // {
  //   id: 3,
  //   title: 'Информационные партнёры',
  //   items: [
  //     {
  //       id: 1,
  //       logo: partner1,
  //       width: 200,
  //     },
  //     {
  //       id: 2,
  //       logo: partner2,
  //       width: 90,
  //     },
  //     {
  //       id: 3,
  //       logo: partner3,
  //       width: 206,
  //     },
  //     {
  //       id: 4,
  //       logo: partner5,
  //       width: 88,
  //     },
  //     {
  //       id: 5,
  //       logo: partner4,
  //       width: 358,
  //     },
  //   ],
  // },
];
