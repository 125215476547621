import styles from './Persons.module.scss';
import personsPlaceholder from '../../assets/images/persons/persons-placeholder@1x.png';
import personsPlaceholderWebp from '../../assets/images/webp/persons/persons-placeholder@1x.webp';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ResponsiveImage from '../Ui/ResponsiveImage/ResponsiveImage';


const cx = classNames.bind(styles);

function Persons({ data, label, className, isOpenDefault, variant }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isOpen, setIsOpen] = useState(isOpenDefault && isMobile);

  useEffect(() => {
    if (!isMobile) {
      setIsOpen(true);
    } else {
      setIsOpen(isOpenDefault);
    }
  }, [isMobile]);

  const titleClassNames = cx({
    title: true,
    titleAccordion: variant === 'accordion',
    tittleOpened: isOpen,
  });

  const listClassNames = cx({
    list: true,
    listAccordion: variant === 'accordion',
  })

  const handleClickOpen = () => {
    if (variant && isMobile) {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <section className={className ? className : ''}>
      <h3 className={titleClassNames} onClick={handleClickOpen}>
        <span className={styles.titleText}>{label}</span>
      </h3>

      {(isOpen || !variant) && (
        <ul className={listClassNames}>
          {data?.map((item) => (
            Object.keys(item).length > 1 ? (
              <li className={styles.item} key={item.id}>
                <ResponsiveImage
                  classNameImage={styles.img}
                  src={item.img}
                  srcWebp={item.imgWebp}
                  alt={item.name}
                />
                <p className={styles.name}>{item.name}</p>
              </li>
            ) : (
              <li className={styles.item} key={item.id}>
                <ResponsiveImage
                  classNameImage={styles.img}
                  src={personsPlaceholder}
                  srcWebp={personsPlaceholderWebp}
                  alt=""
                />
                <p className={styles.name}>Скоро</p>
              </li>
            )
          ))}
        </ul>
      )}
    </section>
  );
}

export default Persons;
