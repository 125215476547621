import styles from './Formats.module.scss';
import Container from '../Container/Container';
import Title from '../Title/Title';
import { useEffect, useState } from 'react';
import api, { HERO_API } from '../../api';
import FormatBuyModal from '../Modals/FormatBuy/FormatBuyModal';
import { getCurrentPrice, times } from '../../utils/times';


function BtnBg() {
  return (
    <svg className={styles.btnBg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 325 60" preserveAspectRatio="none" fill="none">
      <mask id="path-1-inside-1_44_84" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 13.5001V60L305 59.9999L325 39.9999V0H13.5001L0 13.5001Z"/>
      </mask>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 13.5001V60L305 59.9999L325 39.9999V0H13.5001L0 13.5001Z" fill="#BD1818"/>
      <path d="M0 13.5001L-2.12132 11.3787L-3 12.2574V13.5001H0ZM0 60H-3V63H1.20069e-06L0 60ZM305 59.9999V62.9999H306.243L307.121 62.1212L305 59.9999ZM325 39.9999L327.121 42.1212L328 41.2425V39.9999H325ZM325 0H328V-3H325V0ZM13.5001 0V-3H12.2574L11.3787 -2.12132L13.5001 0ZM-3 13.5001V60H3V13.5001H-3ZM1.20069e-06 63L305 62.9999V56.9999L-1.20069e-06 57L1.20069e-06 63ZM307.121 62.1212L327.121 42.1212L322.879 37.8786L302.879 57.8786L307.121 62.1212ZM328 39.9999V0H322V39.9999H328ZM325 -3H13.5001V3H325V-3ZM11.3787 -2.12132L-2.12132 11.3787L2.12132 15.6214L15.6214 2.12132L11.3787 -2.12132Z" fill="url(#paint0_linear_44_84)" mask="url(#path-1-inside-1_44_84)"/>
      <defs>
        <linearGradient id="paint0_linear_44_84" x1="-19" y1="-19" x2="316" y2="74" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBB49"/>
          <stop offset="0.239583" stopColor="#F5EA77"/>
          <stop offset="0.4375" stopColor="#EFEA7A"/>
          <stop offset="0.645833" stopColor="#EAAE3F"/>
          <stop offset="0.8125" stopColor="#E9A638"/>
          <stop offset="1" stopColor="#DE971A"/>
        </linearGradient>
      </defs>
    </svg>
  );
}

function Formats() {
  const [format, setFormat] = useState(null);
  const [formats, setFormats] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    fetchFormats();
  }, []);

  const fetchFormats = async () => {
    try {
      const response = await api.get('/api/event_format/event/heronightout2023');

      if (response.status === 200) setFormats(response.data.values);
    } catch (e) {
      console.log(e)
    }
  };

  const handleClickBuy = (format) => {
    if (format.public_id === 'heronightout2023_amfi' || format.public_id === 'heronightout2023_lozhi') {
      window.location.href = `${HERO_API}/event/heronightout2023/${format.public_id}`
    } else {
      setFormat(format);
      setIsShowModal(true);
    }
  };

  return (
    <section className={styles.formats} id="formats">
      <Container>
        <Title className={styles.title}>Успей купить билет на главный музыкальный фестиваль этой осени!</Title>

        <ul className={styles.list}>
          {formats.map((item) => (
            <li className={styles.item} key={item.public_id}>
              <div className={styles.itemContent}>
                <p className={styles.itemTitle}>{item.title}</p>

                <p>{item.description}</p>

                <p className={styles.priceLabel}>Стоимость</p>

                <p className={styles.price}>{item.price}<span className={styles.currency}>руб</span></p>

                {(item?.prices && getCurrentPrice(item.prices)) && (
                  <p className={`${styles.nextPriceWrap}`}>
                    <span className={styles.nextPriceLabel}>
                      Следующая цена с {times.getNumberShortDate(getCurrentPrice(item.prices).start_date)}
                    </span>

                    <span className={styles.priceNumberNext}>
                      {getCurrentPrice(item.prices).price} <span className={styles.currencyNext}>руб</span>
                    </span>
                  </p>
                )}
              </div>

              <div className={styles.itemBottom}>
                {item?.tickets_left?.athlete < 1 ? (
                  <button className={styles.link} type="button" disabled>
                    <BtnBg />

                    <span className={styles.btnText}>Распродано</span>
                  </button>
                ) : (
                  <button className={styles.link} onClick={() => handleClickBuy(item)} type="button">
                    <BtnBg />

                    <span className={styles.btnText}>Купить</span>
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      </Container>

      <FormatBuyModal format={format} isShowModal={isShowModal} onCloseModal={setIsShowModal} />
    </section>
  );
}

export default Formats;
